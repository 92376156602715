@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    .scrollbar::-webkit-scrollbar {
        width: 15px;
        height: 15px;
    }

    .scrollbar::-webkit-scrollbar-track {
        border-radius: 100vh;
        background: #f7f4ed;
    }

    .scrollbar::-webkit-scrollbar-thumb {
        background: #17a221;
        border-radius: 100vh;
        border: 3px solid #f6f7ed;
    }

    .scrollbar::-webkit-scrollbar-thumb:hover {
        background: #17a221
    }
}